<template>
  <div class="main">
    <a-form-model id="formLogin" ref="form" class="user-layout-login" :model="form" :rules="rules">
      <a-alert
        v-if="isLoginError"
        type="error"
        showIcon
        style="margin-bottom: 24px;"
        :message="loginErrorInfo"
        closable
        :after-close="handleCloseLoginError" />
      <a-form-model-item prop="username" style="margin-bottom:2.4vh;">
        <a-input v-model="form.username" size="large" placeholder="请输入手机号或邮箱">
          <!-- <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
          <!-- <img slot="prefix" style="width: 24px;height:24px;" src="~@/assets/homeImg/phone.png" alt="" srcset=""> -->
        </a-input>
      </a-form-model-item>
      <a-form-model-item v-if="!captchaEnabled" prop="password" style="margin-bottom:0;">
        <a-input-password v-model="form.password" size="large" placeholder="请输入密码">
          <!-- <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
          <!-- <img slot="prefix" style="width: 24px;height:24px;" src="~@/assets/homeImg/pwd.png" alt="" srcset=""> -->
        </a-input-password>
      </a-form-model-item>
      <a-form-model-item v-else prop="code" style="margin-bottom:0;">
        <a-input v-model="form.code" size="large" type="text" autocomplete="off" placeholder="验证码">
          <!-- <a-icon slot="prefix" type="security-scan" :style="{ color: 'rgba(0,0,0,.25)' }" /> -->
          <a-button
            slot="suffix"
            size="large"
            type="link"
            htmlType="submit"
            class="login-button login-buttonCode font16R"
            :loading="codeLoading"
            :disabled="codeLoading"
            @click="handleSendCode">{{ beginning?time+'s后重新获取':'获取验证码' }}</a-button>
        </a-input>
      </a-form-model-item>
      <div class="font14R wjmmBtn" :style="{opacity:!captchaEnabled?1:0}">
        <span :style="{cursor: !captchaEnabled?'pointer':''}" @click="openOther('forgetPassword')">忘记密码?</span>
      </div>
      <!-- <a-form-model-item prop="rememberMe">
        <a-checkbox :checked="form.rememberMe" @change="rememberMe">记住密码</a-checkbox>
      </a-form-model-item> -->
      <a-form-item style="margin-top:2.5vh;margin-bottom:0;">
        <a-button
        v-if="!defaultSettings.sliderVerification"
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button login-buttonSub font18R"
          :loading="logining"
          :disabled="logining"
          @click="handleSubmit">登录</a-button>
          <a-button
          v-if="defaultSettings.sliderVerification"
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button login-buttonSub font18R"
          :loading="logining"
          :disabled="logining"
          @click="handleShowCode">登录</a-button>
      </a-form-item>

      <div class="font14R wjmmBtn" style="justify-content: flex-start;margin-top: 2.2vh;" @click="isChecken=!isChecken">
        <img v-if="!isChecken" style="height: 16px;margin-right: 0.4vw;" src="@/assets/login/7.png">
        <img v-else style="height: 16px;margin-right: 0.4vw;" src="@/assets/login/8.png">
        <span >
          已阅读并同意
          <span style="color: #0087FB;" @click.stop.prevent="openAgreement('用户注册协议')">《用户注册协议》</span>
          与
          <span style="color: #0087FB;" @click.stop.prevent="openAgreement('隐私协议')">《隐私协议》</span>
        </span>
      </div>
      <div class="user-login-other" :style="{opacity: defaultSettings.isQQLogin||defaultSettings.isWxLogin||defaultSettings.isAliLogin||defaultSettings.isIphoneLogin?1:0}">
        <div class="user-login-other-title">
          <div class="b"></div>
          <span class="font16R">或</span>
          <div class="b"></div>
        </div>
        <div class="other-type">
          <!-- <img v-if="defaultSettings.isQQLogin" class="other-type-icon" src="~@/assets/homeImg/qq.png" alt="" srcset=""> -->
          <img v-if="defaultSettings.isWxLogin" class="other-type-icon" src="~@/assets/login/5.png" @click="openOther('wxLogin')">
          <img v-if="defaultSettings.isAliLogin" class="other-type-icon" src="~@/assets/login/4.png" @click="openOther('aliLogin')">
          <!-- <img v-if="defaultSettings.isIphoneLogin" class="other-type-icon" src="~@/assets/homeImg/iphone.png" alt="" srcset=""> -->
        </div>
        <!--
          ruoyi后台不支持获取是否开启账户.
          故此处不做隐藏处理. 在ruoyi原前端中是在注册页面定义一个属性手动修改处理.
          <router-link class="register" :to="{ name: 'register' }">注册账户</router-link>
        -->

      </div>
      <Verify
        v-if="defaultSettings.sliderVerification"
        @success="onSuccess"
        @closeBox="logining=false"
        mode="pop"
        captchaType="blockPuzzle"
        :imgSize="{ width: '330px', height: '155px' }"
        ref="verify"
        ></Verify>
    </a-form-model>
    <!-- 显示用户协议和隐私政策 -->
    <Agreement ref="agreement"></Agreement>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
// import { getCodeImg } from '@/api/login'
import { LOGIN_USERNAME, LOGIN_PASSWORD, LOGIN_REMEMBERME } from '@/store/mutation-types'
import storage from 'store'
import defaultSettings from '@/config/defaultSettings'
import router from '@/router'
import Agreement from '@/components/agreement'
import { validEmail, validPhone } from '@/utils/validate'
import { getCalidCode } from '@/api/login'
import { getConfigKey } from '@/api/system/config'
import { updateTheme, updateColorWeak, colorList } from '@/components/SettingDrawer/settingConfig'
import Verify from "@/components/verifition/Verify";
import { EventBus } from '@/utils/event-bus.js';
export default {
  components: {
    Agreement,
    Verify
  },
  data() {
    const validatorUserName = (rule, value, callback) => {
      if (value) {
        if (this.captchaEnabled && !validEmail(value) && !validPhone(value)) {
          callback(new Error('请输入正确的手机号或邮箱'))
        } else {
          callback()
        }
      } else {
        if (this.captchaEnabled) {
          callback(new Error('请输入手机号或邮箱'))
        } else {
          callback(new Error('请输入帐户名'))
        }
      }
    }
    return {
      isChecken: true, // 默认同意
      defaultSettings,
      codeUrl: '',
      isLoginError: false,
      loginErrorInfo: '',
      form: {
        username: '',
        password: '',
        code: undefined,
        uuid: '',
        rememberMe: false
      },
      rules: {
        username: [{ required: true, validator: validatorUserName, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      logining: false,
      captchaEnabled: false,
      redirect: undefined,

      codeLoading: false, // 获取验证码
      validCodeReqNo: null, // 验证码请求号
      beginning: false, // 是否发送验证码
      time: 60, // 倒计时
      timeInt: null // 倒计时实例
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.getStorage()
    if (this.captchaEnabled) {
      this.getCode()
    }
  },
  beforeDestroy() {
    if (this.timeInt) {
      clearInterval(this.timeInt)
      this.time = 60 // 倒计时60s
      this.beginning = false // 是否显示倒计时改为false
    }
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    getCode(captchaEnabled) {
      this.captchaEnabled = captchaEnabled
      // if (!this.captchaEnabled) {
      //   if (this.timeInt) {
      //     clearInterval(this.timeInt)
      //     this.time = 60 // 倒计时60s
      //     this.beginning = false // 是否显示倒计时改为false
      //   }
      // }
    },
    /**
      * @desc 显示验证码
      * @param {  } 
      * @version: 1.0.0
      */
    handleShowCode(e) {
      console.log('显示验证码>>>>>', e);
      if (!this.isChecken) {
        this.$notification.error({ message: '请阅读并同意《用户注册协议》与《隐私协议》' })
        return
      }
      this.logining = true
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$refs.verify.refresh(() => {
            this.$refs.verify.show()
          })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    onSuccess(params) {
      // console.log(params, '131>>>>>>>>>>>');
      this.handleSubmit()
    },
    getStorage() {
      const username = storage.get(LOGIN_USERNAME)
      const password = storage.get(LOGIN_PASSWORD)
      const rememberMe = storage.get(LOGIN_REMEMBERME)
      if (username) {
        this.form = {
          username: username,
          password: password,
          rememberMe: rememberMe
        }
      }
    },
    rememberMe(e) {
      this.form.rememberMe = e.target.checked
    },
    handleSubmit() {
      if (!this.isChecken) {
        this.$notification.error({ message: '请阅读并同意《用户注册协议》与《隐私协议》' })
        return
      }
      this.logining = true
      console.log('>>>>>', this.$refs.form)

      this.$refs.form.validate(valid => {
        console.log('>>>>1>>>', valid)

        if (valid) {
        console.log('>>>>2>>>', this.form.rememberMe)

          if (this.form.rememberMe) {
            storage.set(LOGIN_USERNAME, this.form.username)
            storage.set(LOGIN_PASSWORD, this.form.password)
            storage.set(LOGIN_REMEMBERME, this.form.rememberMe)
          } else {
            storage.remove(LOGIN_USERNAME)
            storage.remove(LOGIN_PASSWORD)
            storage.remove(LOGIN_REMEMBERME)
          }
        console.log('>>>>3>>>', this.$store)
          const userInfo = JSON.parse(JSON.stringify(this.form))
          userInfo['captchaEnabled'] = this.captchaEnabled
          userInfo['validCodeReqNo'] = this.validCodeReqNo
          this.$store.dispatch('Login', userInfo).then((res) => {
            console.log('999', res)
            console.log('$store.state>>>>>>', this.$store.state)

            this.$store.dispatch('GetInfo')
          .then(res => {
            // const roles = res.result && res.result.role
            const roles = res.roles
            // generate dynamic router
            // getConfigKey('themeColor').then((configRes) => {
            //   console.log('获取主题色>>>>>>',configRes);
            //   updateTheme(configRes.data)
              
            // })
            this.$store.dispatch('GenerateRoutes', { roles }).then((accessRoutes) => {
                console.log('accessRoutes>>>>', accessRoutes)
                if (accessRoutes.data && accessRoutes.data.length === 0) {
                  this.$notification.error({
                    message: '当前账号无菜单权限，请联系管理员'
                  })
                  this.loading = false
                  setTimeout(() => {
                    this.logining = false
                  }, 1000)
                } else {
                  router.addRoutes(this.$store.getters.routers)
                  console.log(accessRoutes, '253>>>>>>>>>>');
                  let quanxianRoutes = {
                    data: accessRoutes.data.filter(item => !item.hidden)
                  }
                  if (quanxianRoutes.data.length > 0) {
                    if (quanxianRoutes.data[0]?.children?.length > 0) {
                      this.$store.commit('SET_DEFAULTROUTEPATH', '/' + quanxianRoutes.data[0].path + '/' + quanxianRoutes.data[0]?.children[0].path)
                      this.$router.push({ path: '/' + quanxianRoutes.data[0].path + '/' + quanxianRoutes.data[0]?.children[0].path }).catch(() => { })
                    } else {
                      console.log('>>>>>>', '/' + quanxianRoutes.data[0].path)

                      this.$store.commit('SET_DEFAULTROUTEPATH', '/' + quanxianRoutes.data[0].path)
                      this.$router.push({ path: '/' + quanxianRoutes.data[0].path }).catch(() => { })
                    }
                  }
                  // 连接mqtt
                  console.log('开始连接mqtt>>>>>');
                  EventBus.$emit('connectMqtt');
                }

                // router.addRoutes(accessRoutes)
                // 请求带有 redirect 重定向时，登录自动重定向到该地址
              })
            
          })
            // this.$router.push({ path: '/jiashicang/index' }).catch(() => { })
            // this.$router.push({ path: this.redirect || '/' }).catch(() => { })
          }).catch((err) => {
            console.log('err', err)
            this.loading = false
            setTimeout(() => {
              this.logining = false
            }, 1000)
          })
          // this.Login(this.form)
          //   .then((res) => this.loginSuccess(res))
          //   .catch(err => this.requestFailed(err))
          //   .finally(() => {
          //     this.logining = false
          //   })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    /**
     * @desc 获取验证码
     * @param {  }
     * @version: 1.0.0
     */
    handleSendCode() {
      // 如果有倒计时，return false
      if (this.beginning) {
        return
      }
      this.codeLoading = true
      this.$refs.form.validateField(['username'], valid => {
        console.log('>>>>1>>>', valid)

        if (!valid) {
          console.log('>>>>2>>>', this.form.rememberMe)

          console.log('>>>>3>>>', this.$store)

          getCalidCode({
            emailOrMobile: this.form.username
          }).then(res => {
            console.log('获取验证码>>>>', res)
            this.codeLoading = false
            this.validCodeReqNo = res.data
            this.beginning = true
            // 清除倒计时,防抖作用
            if (this.timeInt) {
              clearInterval(this.timeInt)
            }
            this.timeInt = setInterval(() => {
              if (this.time === 1) {
                // 倒计时结束就清楚这个倒计时
                clearInterval(this.timeInt)
                this.time = 60 // 倒计时60s
                this.beginning = false // 是否显示倒计时改为false
                return
              }
              this.time--
            }, 1000)
          }).finally(() => {
            this.codeLoading = false
          })
        } else {
          this.codeLoading = false
        }
      })
    },
    loginSuccess(res) {
      this.$router.push({ path: '/' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
      this.handleCloseLoginError()
    },
    requestFailed(err) {
      this.isLoginError = true
      this.loginErrorInfo = err
      this.form.code = undefined
      if (this.captchaEnabled) {
        this.getCode()
      }
    },
    handleCloseLoginError() {
      this.isLoginError = false
      this.loginErrorInfo = ''
    },
    // 打开忘记密码
    openOther(path) {
      if (path === 'forgetPassword' && this.captchaEnabled) {
        return
      }
      this.$router.push({ path: '/user/' + path }).catch(() => { })
    },
    // 打开用户协议和隐私政策
    openAgreement(title) {
      this.isChecken = true
      this.$refs.agreement.init(title)
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  margin: 0 !important;

  /deep/.ant-input {
    height: 4.8vh;
    padding: 0 1.3vh;
    border: 1px solid #E4E4E5;
    font-size: 16px;
    font-family: '思源-Regular';
    border-radius: 10px;
    &:focus {
      border-color:#E4E4E5;
      box-shadow:none;
    }
  }
}

.wjmmBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.6vh;

  span {
    color: #999999;
    cursor: pointer;
  }
  img {
    cursor: pointer;
  }
}
// .main{
//   width: 1920px;
//   height: 1080px;
//   overflow: hidden;
//   background:#d9e2ef url('./img/br.png') no-repeat 100% 46px;

//   &.loginpage-6 {
//     background: url('./img/bg-1.png') no-repeat 100% 100%;
//   }
// }
.user-layout-login {

  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4.8vh;
    color: #2878FF;

    &.login-buttonCode {
      padding: 0;
    }

    &.login-buttonSub {
      color: #FFFFFF;
      background: #2878FF;
      border-radius: 26px 26px 26px 26px;
    }
  }

  .user-login-other {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // text-align: left;
    margin-top: 4.1vh;
    padding-bottom: 4.1vh;
    // line-height: 22px;

    // .register {
    //   float: right;
    // }

    .user-login-other-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2.2vh;

      .b {
        width: 9.8vw;
        height: 0px;
        opacity: 1;
        border: 1px solid #EEEFF1;
      }

      span {
        color: #999999;
      }

    }

    .other-type {

      .other-type-icon {
        width: 3.7vh;
        height:3.7vh;
        margin-right: 1.875vw;
        cursor: pointer;

        &:last-child {
          margin: 0;
        }
      }

    }
  }

  :deep .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
    background: #F7F7F7;
  }

  // :deep .ant-input-affix-wrapper {
  //   background: #F7F7F7;
  // }
}

</style>
